import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { format, parseISO } from "date-fns";
import { useState, useEffect, useCallback, useRef } from "react";
import { Content, Container, InputGroup, Input, IconButton, Panel, Checkbox } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import ReloadIcon from "@rsuite/icons/Reload";
import API from "../../utils/API";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";
import { addHours, startOfDay, endOfDay } from "date-fns";

const TIMEZONE = "Australia/Sydney";

// Calculate initial date range with some buffer days
const calculateInitialDateRange = () => {
  // Create date in Sydney timezone
  const sydneyDate = new Date(formatInTimeZone(new Date(), TIMEZONE, "yyyy-MM-dd'T'HH:mm:ss"));

  const start = startOfDay(sydneyDate);
  const end = endOfDay(sydneyDate);

  return {
    start,
    end,
  };
};

function Schedule() {
  const calendarRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [workorders, setWorkorders] = useState([]);
  const [unassignedWorkorders, setUnassignedWorkorders] = useState([]);
  const [dateRange, setDateRange] = useState(calculateInitialDateRange());
  const [scrollTime, setScrollTime] = useState(new Date().getHours() + ":00:00");

  // Memoize the fetch function
  const fetchData = useCallback(async (startDate, endDate) => {
    try {
      // Format dates in Sydney timezone for API
      const sydneyStart = formatInTimeZone(startDate, TIMEZONE, "yyyy-MM-dd'T'HH:mm:ssXXX");
      const sydneyEnd = formatInTimeZone(endDate, TIMEZONE, "yyyy-MM-dd'T'HH:mm:ssXXX");

      // Fetch users
      const usersResponse = await API.get(`/active_testers`);
      const formattedUsers = usersResponse.data.map(({ id, first_name, last_name, user_level }) => ({
        id,
        title: `${first_name} ${last_name}`,
        user_level: user_level,
      }));
      setUsers(formattedUsers);

      // Fetch workorders
      const workordersResponse = await API.get("/workorders/schedule/", {
        params: {
          daterange_from: sydneyStart,
          daterange_to: sydneyEnd,
        },
      });

      if (workordersResponse.data) {
        const assigned = [];
        const unassigned = [];

        workordersResponse.data.forEach((wo) => {
          const formattedWorkorder = formatWorkorder(wo);
          if (wo.created_by && wo.created_by.id) {
            assigned.push(formattedWorkorder);
          } else {
            unassigned.push(formattedWorkorder);
          }
        });

        setWorkorders(assigned);
        setUnassignedWorkorders(unassigned);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  // Handle date range changes
  const handleDatesSet = useCallback(
    ({ start, end }) => {
      // Update dateRange state for display
      setDateRange({
        start: start,
        end: end,
      });

      // Fetch new data
      fetchData(start, end);
    },
    [fetchData]
  );

  return (
    <Container fluid style={{ padding: 0 }}>
      <div
        className="scheduler-header"
        style={{
          padding: "10px",
          borderBottom: "1px solid #e5e5e5",
          backgroundColor: "#f7f7f7",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left side - Date Navigation */}
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <h4 style={{ margin: 0, fontWeight: 500 }}>{formatInTimeZone(dateRange.start, TIMEZONE, "MMMM d, yyyy")}</h4>

          <div className="fc-button-group" style={{ display: "flex" }}>
            <button className="fc-prev-button fc-button fc-button-primary" type="button" onClick={() => calendarRef.current.getApi().prev()}>
              <span className="fc-icon fc-icon-chevron-left"></span>
            </button>
            <button className="fc-next-button fc-button fc-button-primary" type="button" onClick={() => calendarRef.current.getApi().next()}>
              <span className="fc-icon fc-icon-chevron-right"></span>
            </button>
            <button className="fc-today-button fc-button fc-button-primary" type="button" onClick={() => calendarRef.current.getApi().today()}>
              today
            </button>
          </div>
        </div>

        {/* Right side - Reload button */}
        <div>
          <IconButton
            icon={<ReloadIcon />}
            appearance="subtle"
            onClick={() => {
              const api = calendarRef.current.getApi();
              fetchData(api.view.activeStart, api.view.activeEnd);
            }}
          />
        </div>
      </div>

      {/* Rest of the calendar */}
      <div className="scheduler-container" style={{ display: "flex", height: "calc(100vh - 60px)", overflow: "hidden" }}>
        {/* Unassigned Panel */}
        <Panel
          className="unassigned-panel"
          style={{
            width: "300px",
            borderRight: "1px solid #e5e5e5",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
          }}
        >
          <div
            style={{
              padding: "0px",
              borderBottom: "1px solid #e5e5e5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h6>UNASSIGNED WORK ORDERS ({unassignedWorkorders.length})</h6>
          </div>

          <div style={{ overflowY: "auto", flex: 1 }}>
            {unassignedWorkorders.map((wo) => (
              <div
                key={wo.id}
                className="unassigned-work-order"
                draggable
                style={{
                  padding: "10px",
                  margin: "8px",
                  backgroundColor: "#fff",
                  border: "1px solid #ffd8d8",
                  borderLeft: "4px solid #ff4444",
                  borderRadius: "4px",
                  cursor: "move",
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: "13px" }}>{wo.id}</div>
                <div style={{ fontSize: "12px", color: "#666" }}>{wo.title}</div>
                <div style={{ fontSize: "11px", color: "#999", marginTop: "4px" }}>
                  {format(new Date(wo.start), "h:mm a")} to {format(new Date(wo.end), "h:mm a")}
                </div>
              </div>
            ))}
          </div>
        </Panel>

        {/* Main Calendar Area */}
        <div style={{ flex: 1, overflow: "hidden" }}>
          <FullCalendar
            ref={calendarRef}
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            plugins={[resourceTimelinePlugin, interactionPlugin]}
            initialView="resourceTimelineDay"
            initialDate={formatInTimeZone(new Date(), TIMEZONE, "yyyy-MM-dd")}
            slotMinTime="04:00:00"
            slotMaxTime="20:00:00"
            slotDuration={{
              hours: 1,
            }}
            expandRows={false}
            height="calc(100vh - 170px)"
            scrollTime="06:00:00"
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: true,
              meridiem: "short",
            }}
            views={{
              resourceTimelineDay: {
                slotDuration: { hours: 1 },
                // Customize slot appearance based on time
                slotLabelDidMount: (arg) => {
                  const hour = arg.date.getHours();
                  // Business hours: 6am - 6pm
                  if (hour < 6 || hour >= 18) {
                    arg.el.style.fontSize = "11px";
                    arg.el.style.color = "#999";
                    arg.el.parentElement.style.backgroundColor = "#f9f9f9";
                    arg.el.parentElement.style.width = "45px"; // Smaller width for off-hours
                  } else {
                    arg.el.style.fontSize = "12px"; // Fixed business hours font size
                    arg.el.style.color = "#333";
                    arg.el.parentElement.style.width = "80px"; // Normal width for business hours
                  }
                },
                // Customize slots based on time
                slotDidMount: (arg) => {
                  const hour = arg.date.getHours();
                  if (hour < 6 || hour >= 18) {
                    arg.el.style.backgroundColor = "#f9f9f9";
                    arg.el.style.width = "45px"; // Match width for off-hours
                  } else {
                    arg.el.style.width = "80px"; // Match width for business hours
                  }
                },
              },
            }}
            slotMinWidth={null} // Remove default slot min width
            businessHours={{
              startTime: "06:00",
              endTime: "18:00",
              daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            }}
            resourceAreaWidth="140px"
            resourceAreaColumns={[
              {
                field: "title",
                headerContent: "Technician",
                width: 140,
                resizable: false,
              },
            ]}
            resourceOrder="user_level,title"
            resources={users}
            events={workorders}
            datesSet={handleDatesSet}
            eventOverlap={false}
            allDaySlot={true}
            navLinks={false}
            timeZone={TIMEZONE}
            headerToolbar={false}
            eventMinHeight={30} // Minimum height for events
            displayEventEnd={true} // Show end time
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "short",
            }}
          />
        </div>
      </div>
    </Container>
  );
}

// Helper function to format workorder data
const formatWorkorder = (wo) => {
  // Get title based on priority
  let title = "";
  let address = "";

  if (wo.project) {
    // Priority 1: Project name and address
    title = wo.project.name;
    const location = wo.project.locations?.find((loc) => loc.is_primary);
    if (location) {
      address = [location.address_line_1, location.address_line_2].filter(Boolean).join(", ");
    }
  } else if (wo.billing_customer) {
    // Priority 2: Billing customer and field address
    title = wo.billing_customer.name;
    address = [wo.field_address_line_1, wo.field_address_line_2].filter(Boolean).join(", ");
  } else if (wo.field_customer || wo.field_address_line_1) {
    // Priority 3: Field customer and address
    title = wo.field_customer || "";
    address = [wo.field_address_line_1, wo.field_address_line_2].filter(Boolean).join(", ");
  }

  // Combine title and address
  const displayTitle = [title, address].filter(Boolean).join(" - ");

  // Check if we only have work_date (no specific times)
  const isAllDay = wo.work_date && !wo.work_start_time && !wo.work_finish_time;

  let startTime, endTime;

  if (isAllDay) {
    // Create all-day event
    startTime = new Date(wo.work_date);
    endTime = new Date(wo.work_date);
    // For all-day events, end date should be the next day
    endTime.setDate(endTime.getDate() + 1);
  } else {
    // Existing time logic
    if (wo.work_start_time) {
      startTime = new Date(`${wo.work_date.split("T")[0]}T${wo.work_start_time.split("T")[1]}`);
    } else if (wo.created_date) {
      startTime = new Date(wo.created_date);
    } else {
      startTime = new Date(wo.work_date);
      startTime.setHours(9, 0, 0);
    }

    if (wo.work_finish_time) {
      endTime = new Date(`${wo.work_date.split("T")[0]}T${wo.work_finish_time.split("T")[1]}`);
    } else {
      endTime = new Date(startTime);
      endTime.setHours(startTime.getHours() + 2);
    }
  }

  // Validate times and ensure they're on the same day as work_date
  if (!isValidDate(startTime)) {
    startTime = new Date(wo.work_date);
    startTime.setHours(9, 0, 0);
  }

  if (!isValidDate(endTime) || endTime < startTime) {
    endTime = new Date(startTime);
    endTime.setHours(startTime.getHours() + 2);
  }

  return {
    id: wo.id,
    title: displayTitle || "Untitled",
    start: startTime,
    end: endTime,
    allDay: isAllDay,
    resourceIds: wo.created_by ? [wo.created_by.id] : [],
    backgroundColor: wo.field_complete ? "#E3E3E3" : "#4CAF50",
    borderColor: wo.field_complete ? "#CCCCCC" : "#45a049",
    textColor: wo.field_complete ? "#666666" : "#FFFFFF",
    display: "block",
    extendedProps: {
      ...wo,
      estimated_duration: isAllDay ? "All day" : "2 hours",
    },
  };
};

// Helper function to validate dates
const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date) && date.getFullYear() > 2000;
};

export default Schedule;
