import React, { useContext, useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { AccountConsumer } from "../context/AccountProvider";

import { Sidebar, Sidenav, Nav, Badge } from "rsuite";
import DashboardIcon from "@rsuite/icons/Dashboard";
import SingleSourceIcon from "@rsuite/icons/SingleSource";
import TextImageIcon from "@rsuite/icons/TextImage";
import PeoplesIcon from "@rsuite/icons/Peoples";
import ScatterIcon from "@rsuite/icons/Scatter";
import DocPassIcon from "@rsuite/icons/DocPass";
import EventDetailIcon from "@rsuite/icons/EventDetail";
import ListIcon from "@rsuite/icons/List";
import SearchIcon from "@rsuite/icons/Search";
import ExitIcon from "@rsuite/icons/Exit";
import API from "../utils/API";

// const { getHeight, on } = DOMHelper;

export default function Navigation(props) {
  const [meta, set_meta] = useState();
  const acc = useContext(AccountConsumer);
  // console.log();

  if (acc.user_id && acc.is_field_only) {
    localStorage.clear();
    window.location.href = "https://field.wsctapps.com/";
  }

  const getMetaInformation = useCallback(() => {
    // console.log(id);
    if (acc.user_id > 0) {
      API.get(`/billing/uncharged_count`)
        .then((r) => {
          set_meta(r.data);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  useEffect(() => {
    getMetaInformation();
  }, [getMetaInformation]);

  useEffect(() => {
    const interval = setInterval(() => {
      getMetaInformation();
    }, 30000);
    return () => clearInterval(interval);
  }, [getMetaInformation]);

  const handleToggle = (expanded) => {
    acc.updateSidenavOpen(expanded);
  };

  return acc.user_id ? (
    <Sidebar style={{ display: "flex", flexDirection: "column", backgroundColor: "#f7f7fa" }} collapsible width={acc.sidenav_open ? 260 : 56}>
      <Sidenav defaultOpenKeys={["2", "4"]} expanded={acc.sidenav_open}>
        <Sidenav.Body>
          <Nav defaultActiveKey="2">
            {acc.user_level >= 5 && (
              <Nav.Item as={Link} to="dashboard" eventKey="1" icon={<DashboardIcon />}>
                Dashboard
              </Nav.Item>
            )}

            <Nav.Menu eventKey="2" title="Workorders" icon={<TextImageIcon />}>
              <Nav.Item as={Link} to="workorders" eventKey="2-1">
                All
              </Nav.Item>

              {acc.user_level >= 5 && (
                <>
                  <Nav.Item as={Link} to="workorders/billing" eventKey="2-2" style={{ display: "flex", justifyContent: "space-between" }}>
                    Billing
                    {meta > 0 && <Badge content={meta} color="red" />}
                  </Nav.Item>
                  <Nav.Item as={Link} to="workorders/invoicing">
                    Invoicing
                  </Nav.Item>
                </>
              )}
            </Nav.Menu>

            <Nav.Item as={Link} to="projects" eventKey="3" icon={<SingleSourceIcon />}>
              Projects
            </Nav.Item>
            <Nav.Item as={Link} to="clients" eventKey="4" icon={<PeoplesIcon />}>
              Clients
            </Nav.Item>
            <Nav.Item as={Link} to="/tests/concrete/crushing" eventKey="5" icon={<ScatterIcon />}>
              Testing
            </Nav.Item>
            <Nav.Item as={Link} to="reports" eventKey="6" icon={<DocPassIcon />}>
              Reports
            </Nav.Item>

            <Nav.Item as={Link} to="search" eventKey="7" icon={<SearchIcon />}>
              Search
            </Nav.Item>

            {acc.user_level >= 5 && (
              <Nav.Item as={Link} to="lists" eventKey="8" icon={<ListIcon />}>
                Lists
              </Nav.Item>
            )}

            <Nav.Item as={Link} to="schedule" eventKey="9" icon={<EventDetailIcon />}>
              Schedule
            </Nav.Item>
            <Nav.Item as={Link} to="logout" eventKey="10" icon={<ExitIcon />}>
              Logout
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
        <Sidenav.Toggle onToggle={handleToggle} />
      </Sidenav>
    </Sidebar>
  ) : null;
}
