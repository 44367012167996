import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, AreaChart, Area, Treemap } from "recharts";
import { format, parseISO } from "date-fns";

import API from "../../utils/API";

import { DateRangePicker, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, SelectPicker } from "rsuite";
import CylinderStatsChart from "./CylinderStatsChart";

import { Container, Content } from "rsuite";

function Dashboard() {
  let navigate = useNavigate();
  const [is_loading, set_is_loading] = useState(true);
  const [cylinder_stats, set_cylinder_stats] = useState([]);
  const [cylinder_stats_by_cust, set_cylinder_stats_by_cust] = useState([]);
  const [tester_stats, set_tester_stats] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  // Color gradient function
  const getColor = (value) => {
    // Define color stops with more extreme gradient
    const colors = {
      100: "#00563F", // Dark forest green
      97: "#008148", // Forest green
      95: "#00A65A", // Green
      90: "#92D14F", // Light green
      85: "#FFC107", // Amber
      80: "#FF9800", // Orange
      75: "#FF5722", // Deep Orange
      0: "#D32F2F", // Red
    };

    // Find the closest color stops
    const stops = Object.keys(colors)
      .map(Number)
      .sort((a, b) => b - a);
    const upperStop = stops.find((stop) => value >= stop) || stops[stops.length - 1];
    const lowerStop = stops[stops.findIndex((stop) => stop === upperStop) + 1] || stops[stops.length - 1];

    if (upperStop === lowerStop) return colors[upperStop];

    // Calculate the color interpolation
    const upperColor = colors[upperStop];
    const lowerColor = colors[lowerStop];

    // Convert hex to RGB and interpolate
    const upperRGB = hexToRgb(upperColor);
    const lowerRGB = hexToRgb(lowerColor);

    const ratio = (value - lowerStop) / (upperStop - lowerStop);

    const r = Math.round(lowerRGB.r + (upperRGB.r - lowerRGB.r) * ratio);
    const g = Math.round(lowerRGB.g + (upperRGB.g - lowerRGB.g) * ratio);
    const b = Math.round(lowerRGB.b + (upperRGB.b - lowerRGB.b) * ratio);

    return `rgb(${r}, ${g}, ${b})`;
  };

  // Helper function to convert hex to RGB
  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const getCylinderStats = useCallback(() => {
    API.get(`/dashboard/cylinder_stats`)
      .then((r) => {
        const data = r.data;
        set_cylinder_stats(data);
      })
      .catch((e) => console.log(e));
  }, []);

  const getCylinderStatsByCust = useCallback(() => {
    API.get(`/dashboard/cylinder_stats_by_cust`)
      .then((r) => {
        set_cylinder_stats_by_cust(r.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const transformCustomerData = (data) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const customerData = {};

    // Group by month and customer
    data.forEach((item) => {
      const monthKey = `${item.year}-${months[item.month - 1]}`;
      if (!customerData[monthKey]) {
        customerData[monthKey] = {
          month: monthKey,
          total: 0,
        };
      }
      customerData[monthKey][item.billing_customer_name] = item.specimen_count;
      customerData[monthKey].total += item.specimen_count;
    });

    // Convert to array and sort by date
    return Object.values(customerData).sort((a, b) => {
      const [yearA, monthA] = a.month.split("-");
      const [yearB, monthB] = b.month.split("-");
      return yearA === yearB ? months.indexOf(monthA) - months.indexOf(monthB) : yearA - yearB;
    });
  };

  const getTesterStats = useCallback(() => {
    API.get(`/dashboard/tester_stats`)
      .then((r) => {
        const data = r.data;
        // Calculate mass difference percentage and process completion rates
        const processedData = data
          .map((tester) => {
            const completionRates = {
              supplier: tester.sample_quality.supplier_completion_rate || 0,
              temperature: tester.sample_quality.temperature_completion_rate || 0,
              slump: tester.sample_quality.slump_completion_rate || 0,
              location: tester.sample_quality.location_completion_rate || 0,
              docket: tester.sample_quality.docket_completion_rate || 0,
            };

            const totalScore = (completionRates.supplier + completionRates.temperature + completionRates.slump + completionRates.location + completionRates.docket) / 5; // Average of all rates

            return {
              ...tester,
              sample_quality: {
                ...tester.sample_quality,
                mass_difference_percent: (tester.sample_quality.avg_mass_diff / 2300) * 100,
                completion_rates: completionRates,
                total_score: totalScore,
              },
            };
          })
          .sort((a, b) => b.sample_quality.total_score - a.sample_quality.total_score);

        set_tester_stats(processedData);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getCylinderStats();
    getCylinderStatsByCust();
    getTesterStats();
  }, [getCylinderStats, getCylinderStatsByCust, getTesterStats]);

  // Custom tooltip for completion rates
  const completionRatesTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const rates = payload[0].payload.sample_quality.completion_rates;
      const score = payload[0].payload.sample_quality.total_score;
      const color = getColor(score);

      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{label}</p>
          <p style={{ margin: "0 0 5px 0", color: color, fontWeight: "bold" }}>Overall Score: {score.toFixed(1)}%</p>
          <div style={{ borderTop: "1px solid #eee", marginTop: "5px", paddingTop: "5px" }}>
            <p style={{ margin: "2px 0", color: "#8884d8" }}>Supplier Info: {rates.supplier.toFixed(1)}%</p>
            <p style={{ margin: "2px 0", color: "#82ca9d" }}>Temperature: {rates.temperature.toFixed(1)}%</p>
            <p style={{ margin: "2px 0", color: "#ffc658" }}>Slump: {rates.slump.toFixed(1)}%</p>
            <p style={{ margin: "2px 0", color: "#ff8042" }}>Location: {rates.location.toFixed(1)}%</p>
            <p style={{ margin: "2px 0", color: "#00C49F" }}>Docket: {rates.docket.toFixed(1)}%</p>
          </div>
        </div>
      );
    }
    return null;
  };

  // Custom label for the bars
  const CustomBarLabel = ({ x, y, width, height, value }) => {
    const padding = 5;
    return (
      <text x={x + width + padding} y={y + height / 2} fill="#666" textAnchor="start" dominantBaseline="central" fontSize={12} fontWeight="bold">
        {`${value.toFixed(1)}%`}
      </text>
    );
  };

  // Custom tooltip for Sample Quality Metrics
  const sampleQualityTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{label}</p>
          {payload.map((entry, index) => (
            <p
              key={index}
              style={{
                margin: "2px 0",
                color: entry.color,
                fontWeight: entry.name === "Mass Difference" ? "bold" : "normal",
              }}
            >
              {entry.name}:{" "}
              {entry.name === "Mass Difference" ? `${entry.value.toFixed(2)}%` : entry.name === "28-Day MPa Diff" ? `${entry.value.toFixed(1)} MPa` : entry.value.toFixed(2)}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  // Color functions for quality metrics
  const getMpaColor = (value) => {
    // Lower MPa difference is better
    const colors = {
      0: "#00563F", // Dark forest green (excellent)
      1: "#00A65A", // Green (very good)
      2: "#92D14F", // Light green (good)
      3: "#FFC107", // Amber (fair)
      4: "#FF5722", // Deep Orange (needs improvement)
      5: "#D32F2F", // Red (poor)
    };

    const stops = Object.keys(colors)
      .map(Number)
      .sort((a, b) => a - b);
    const lowerStop = stops.find((stop) => value <= stop) || stops[stops.length - 1];
    const upperStop = stops[stops.findIndex((stop) => stop === lowerStop) + 1] || stops[stops.length - 1];

    if (upperStop === lowerStop) return colors[upperStop];

    const ratio = (value - lowerStop) / (upperStop - lowerStop);
    const lowerColor = hexToRgb(colors[lowerStop]);
    const upperColor = hexToRgb(colors[upperStop]);

    const r = Math.round(lowerColor.r + (upperColor.r - lowerColor.r) * ratio);
    const g = Math.round(lowerColor.g + (upperColor.g - lowerColor.g) * ratio);
    const b = Math.round(lowerColor.b + (upperColor.b - lowerColor.b) * ratio);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const getMassColor = (value) => {
    // Lower mass difference is better
    const colors = {
      0: "#00563F", // Dark forest green (excellent)
      50: "#00A65A", // Green (very good)
      100: "#92D14F", // Light green (good)
      150: "#FFC107", // Amber (fair)
      200: "#FF5722", // Deep Orange (needs improvement)
      250: "#D32F2F", // Red (poor)
    };

    const stops = Object.keys(colors)
      .map(Number)
      .sort((a, b) => a - b);
    const lowerStop = stops.find((stop) => value <= stop) || stops[stops.length - 1];
    const upperStop = stops[stops.findIndex((stop) => stop === lowerStop) + 1] || stops[stops.length - 1];

    if (upperStop === lowerStop) return colors[upperStop];

    const ratio = (value - lowerStop) / (upperStop - lowerStop);
    const lowerColor = hexToRgb(colors[lowerStop]);
    const upperColor = hexToRgb(colors[upperStop]);

    const r = Math.round(lowerColor.r + (upperColor.r - lowerColor.r) * ratio);
    const g = Math.round(lowerColor.g + (upperColor.g - lowerColor.g) * ratio);
    const b = Math.round(lowerColor.b + (upperColor.b - lowerColor.b) * ratio);

    return `rgb(${r}, ${g}, ${b})`;
  };

  // Custom label for quality metrics
  const CustomQualityLabel = ({ x, y, width, height, value, dataKey }) => {
    if (!value || value === 0) return null;

    const label = dataKey === "sample_quality.avg_28day_pair_diff" ? `${value.toFixed(1)} MPa` : `${value.toFixed(1)}%`;

    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={11}
        fontWeight="bold"
        style={{
          filter: "drop-shadow(1px 1px 1px rgba(0,0,0,0.5))",
        }}
      >
        {label}
      </text>
    );
  };

  // Color step function for completion rates
  const getCompletionColor = (value) => {
    // Step colors based on 5% intervals
    if (value >= 95) return "#2E7D32"; // Green
    if (value >= 90) return "#FF9800"; // Orange
    return "#D32F2F"; // Red
  };

  const CustomerCylinderChart = ({ data }) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return <div>No data available</div>;
    }

    // Transform data for treemap
    const transformData = (rawData) => {
      try {
        // Calculate total cylinders for each customer
        const customerTotals = rawData
          .map((customer) => {
            const totalCylinders = customer.stats.reduce((sum, stat) => sum + stat.cylinders, 0);
            return {
              name: customer.customer,
              size: totalCylinders,
              // Add monthly breakdown for tooltip
              monthlyBreakdown: customer.stats
                .filter((stat) => stat.cylinders > 0)
                .map((stat) => ({
                  month: new Date(stat.month).toLocaleDateString("en-US", {
                    month: "short",
                    year: "numeric",
                  }),
                  cylinders: stat.cylinders,
                })),
            };
          })
          .filter((customer) => customer.size > 0); // Remove customers with 0 cylinders

        return [
          {
            name: "Cylinders by Customer",
            children: customerTotals,
          },
        ];
      } catch (error) {
        console.error("Error transforming data:", error);
        return [];
      }
    };

    const transformedData = transformData(data);

    if (transformedData.length === 0) {
      return <div>No data available for visualization</div>;
    }

    // Custom tooltip to show monthly breakdown
    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length > 0) {
        const data = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <p style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{data.name}</p>
            <p style={{ margin: "0 0 5px 0" }}>Total: {data.size} cylinders</p>
            {data.monthlyBreakdown && data.monthlyBreakdown.length > 0 && (
              <div>
                <p style={{ margin: "5px 0", borderTop: "1px solid #eee", paddingTop: "5px" }}>Monthly Breakdown:</p>
                {data.monthlyBreakdown.map((month, idx) => (
                  <p key={idx} style={{ margin: "2px 0", fontSize: "0.9em" }}>
                    {month.month}: {month.cylinders} cylinders
                  </p>
                ))}
              </div>
            )}
          </div>
        );
      }
      return null;
    };

    return (
      <div style={{ width: 800, height: 800 }}>
        <h3 style={{ textAlign: "center" }}>Customer Distribution by Cylinder Volume</h3>
        <ResponsiveContainer>
          <Treemap data={transformedData} dataKey="size" ratio={4 / 3} stroke="#fff" fill="#8884d8" content={<CustomizedContent />}>
            <Tooltip content={<CustomTooltip />} />
          </Treemap>
        </ResponsiveContainer>
      </div>
    );
  };

  // Custom content component for treemap tiles
  const CustomizedContent = ({ root, depth, x, y, width, height, index, payload, colors, rank, name, size }) => {
    const colors_1 = [
      "#8884d8",
      "#82ca9d",
      "#ffc658",
      "#ff7300",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
    ];

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: colors_1[index % colors_1.length],
            stroke: "#fff",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {width > 50 && height > 30 && (
          <text
            x={x + width / 2}
            y={y + height / 2}
            textAnchor="middle"
            fill="#fff"
            fontSize={14}
            style={{
              pointerEvents: "none",
            }}
          >
            <tspan x={x + width / 2} dy="-0.5em">
              {name}
            </tspan>
            <tspan x={x + width / 2} dy="1.2em">
              {size}
            </tspan>
          </text>
        )}
      </g>
    );
  };

  return (
    <Container>
      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Dashboard</Heading>
            </Stack.Item>
          </Stack>
        </Panel>
        {cylinder_stats && <CylinderStatsChart rawData={cylinder_stats} />}

        {tester_stats && tester_stats.length > 0 && (
          <>
            <Panel header="Tester Productivity Metrics" style={{ marginTop: "20px" }}>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={tester_stats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tester_name" angle={-45} textAnchor="end" height={100} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="productivity.workorders_per_day" name="Workorders/Day" fill="#8884d8" />
                  <Bar dataKey="productivity.avg_samples_per_workorder" name="Samples/Workorder" fill="#82ca9d" />
                  <Bar dataKey="productivity.cylinders_per_day" name="Cylinders/Day" fill="#ffc658" />
                </BarChart>
              </ResponsiveContainer>
            </Panel>

            <Panel header="Sample Quality Metrics" style={{ marginTop: "20px" }}>
              <div style={{ marginBottom: "10px", padding: "0 20px" }}>
                <Text>
                  Stacked bars show 28-day MPa difference (left) and mass difference percentage (right). Lower values indicate better quality control. Colors indicate performance
                  level from excellent (dark green) to needs improvement (red).
                </Text>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={[...tester_stats].sort(
                    (a, b) =>
                      a.sample_quality.avg_28day_pair_diff +
                      a.sample_quality.mass_difference_percent -
                      (b.sample_quality.avg_28day_pair_diff + b.sample_quality.mass_difference_percent)
                  )}
                  margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tester_name" angle={-45} textAnchor="end" height={70} interval={0} tick={{ fontSize: 12 }} />
                  <YAxis
                    label={{
                      value: "Difference (MPa / %)",
                      angle: -90,
                      position: "insideLeft",
                      style: { textAnchor: "middle" },
                    }}
                  />
                  <Tooltip content={sampleQualityTooltip} />
                  <Legend verticalAlign="top" height={36} />
                  <Bar dataKey="sample_quality.avg_28day_pair_diff" name="28-Day MPa Diff" stackId="a" label={CustomQualityLabel}>
                    {tester_stats.map((entry) => (
                      <Cell key={`mpa-${entry.tester_id}`} fill={getMpaColor(entry.sample_quality.avg_28day_pair_diff)} />
                    ))}
                  </Bar>
                  <Bar dataKey="sample_quality.mass_difference_percent" name="Mass Difference" stackId="a" label={CustomQualityLabel}>
                    {tester_stats.map((entry) => (
                      <Cell key={`mass-${entry.tester_id}`} fill={getMassColor(entry.sample_quality.mass_difference_percent)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Panel>

            <Panel header="Documentation Completion Rates (%)" style={{ marginTop: "20px" }}>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={tester_stats}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tester_name" angle={-45} textAnchor="end" height={100} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="documentation.signature_collection_rate" name="Signature Collection" fill="#8884d8" />
                  <Bar dataKey="documentation.field_complete" name="Field Complete" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </Panel>

            <Panel header="Sample Quality Completion Rates" style={{ marginTop: "20px" }}>
              <div style={{ marginBottom: "10px", padding: "0 20px" }}>
                <Text>
                  Bars show completion rates for different quality metrics. Colors indicate performance level:
                  <span style={{ color: "#2E7D32", fontWeight: "bold" }}> ≥95% </span>
                  <span style={{ color: "#FF9800", fontWeight: "bold" }}> ≥90% </span>
                  <span style={{ color: "#D32F2F", fontWeight: "bold" }}> &lt;90% </span>
                </Text>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={[...tester_stats].sort((a, b) => {
                    const aTotal =
                      (a.sample_quality.supplier_completion_rate +
                        a.sample_quality.temperature_completion_rate +
                        a.sample_quality.slump_completion_rate +
                        a.sample_quality.location_completion_rate +
                        a.documentation.docket_completion_rate) /
                      5;
                    const bTotal =
                      (b.sample_quality.supplier_completion_rate +
                        b.sample_quality.temperature_completion_rate +
                        b.sample_quality.slump_completion_rate +
                        b.sample_quality.location_completion_rate +
                        b.documentation.docket_completion_rate) /
                      5;
                    return bTotal - aTotal;
                  })}
                  margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                  barGap={2}
                  barCategoryGap={30}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="tester_name" angle={-45} textAnchor="end" height={70} interval={0} tick={{ fontSize: 12 }} />
                  <YAxis
                    domain={[0, 100]}
                    label={{
                      value: "Completion Rate (%)",
                      angle: -90,
                      position: "insideLeft",
                      style: { textAnchor: "middle" },
                    }}
                  />
                  <Tooltip content={completionRatesTooltip} />
                  <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(value) => {
                      const styles = {
                        "Supplier Info": { fontWeight: "bold", color: "#1a237e" },
                        Temperature: { fontWeight: "bold", color: "#0d47a1" },
                        Slump: { fontWeight: "bold", color: "#1565c0" },
                        Location: { fontWeight: "bold", color: "#1976d2" },
                        Docket: { fontWeight: "bold", color: "#1e88e5" },
                      };
                      return <span style={styles[value]}>{value}</span>;
                    }}
                  />
                  <Bar dataKey="sample_quality.supplier_completion_rate" name="Supplier Info" maxBarSize={50} style={{ filter: "brightness(1.1)" }}>
                    {tester_stats.map((entry) => (
                      <Cell key={`supplier-${entry.tester_id}`} fill={getCompletionColor(entry.sample_quality.supplier_completion_rate)} />
                    ))}
                  </Bar>
                  <Bar dataKey="sample_quality.temperature_completion_rate" name="Temperature" maxBarSize={50} style={{ filter: "brightness(1.05)" }}>
                    {tester_stats.map((entry) => (
                      <Cell key={`temp-${entry.tester_id}`} fill={getCompletionColor(entry.sample_quality.temperature_completion_rate)} />
                    ))}
                  </Bar>
                  <Bar dataKey="sample_quality.slump_completion_rate" name="Slump" maxBarSize={50} style={{ filter: "brightness(1)" }}>
                    {tester_stats.map((entry) => (
                      <Cell key={`slump-${entry.tester_id}`} fill={getCompletionColor(entry.sample_quality.slump_completion_rate)} />
                    ))}
                  </Bar>
                  <Bar dataKey="sample_quality.location_completion_rate" name="Location" maxBarSize={50} style={{ filter: "brightness(0.95)" }}>
                    {tester_stats.map((entry) => (
                      <Cell key={`location-${entry.tester_id}`} fill={getCompletionColor(entry.sample_quality.location_completion_rate)} />
                    ))}
                  </Bar>
                  <Bar dataKey="documentation.docket_completion_rate" name="Docket" maxBarSize={50} style={{ filter: "brightness(0.9)" }}>
                    {tester_stats.map((entry) => (
                      <Cell key={`docket-${entry.tester_id}`} fill={getCompletionColor(entry.documentation.docket_completion_rate)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Panel>

            <Panel header="Customer Specimen Distribution" style={{ marginTop: "20px", marginBottom: "2rem" }}>
              {cylinder_stats_by_cust && Array.isArray(cylinder_stats_by_cust) && cylinder_stats_by_cust.length > 0 && <CustomerCylinderChart data={cylinder_stats_by_cust} />}
            </Panel>
          </>
        )}
      </Content>
    </Container>
  );
}

export default Dashboard;
