import { useCallback, useEffect, useState } from "react";
import { Input, InputGroup, Container, Content, Stack, Panel, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, Table, Drawer, List, HStack } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import SearchIcon from "@rsuite/icons/Search";

import ProjectList from "../../components/ProjectList";
import API from "../../utils/API";
import { Link, useNavigate } from "react-router-dom";

function Search() {
  const navigate = useNavigate();
  const { Column, HeaderCell, Cell } = Table;

  const [open, setOpen] = useState(false);
  const [selected_list, setSelectedList] = useState(undefined);

  const [is_loading, set_is_loading] = useState(true);
  const [projects, set_projects] = useState();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("workorders");

  const fetchSearchResults = useCallback(() => {
    set_is_loading(true);
    API.get(`/${searchType}/search`, {
      params: {
        page: pageNumber,
        limit: 20,
        query: searchTerm,
      },
    })
      .then((r) => {
        set_projects(r.data.data);
        setNumPages(r.data.pages);
        set_is_loading(false);
      })
      .catch((e) => console.log(e));
  }, [pageNumber, searchType, searchTerm]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchSearchResults();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm, searchType]);

  const renderColumns = () => {
    const commonColumns = (
      <>
        <Column width={60}>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="id" />
        </Column>
      </>
    );

    const typeColumns = {
      workorders: (
        <>
          <Column width={200}>
            <HeaderCell>Project</HeaderCell>
            <Cell dataKey="project_name" />
          </Column>
          <Column width={150}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
        </>
      ),
      samples: (
        <>
          <Column width={150}>
            <HeaderCell>Mix Code</HeaderCell>
            <Cell dataKey="mix_code" />
          </Column>

          <Column width={100}>
            <HeaderCell>Workorder</HeaderCell>
            <Cell>
              {(rowData) => (
                <Link to={`/workorders/${rowData.workorder_id}`} onClick={(e) => e.stopPropagation()}>
                  #{rowData.workorder_id}
                </Link>
              )}
            </Cell>
          </Column>
        </>
      ),
      cylinders: (
        <>
          <Column width={150}>
            <HeaderCell>Specimen ID</HeaderCell>
            <Cell dataKey="specimen_id" />
          </Column>
          <Column width={150}>
            <HeaderCell>Mould Number</HeaderCell>
            <Cell dataKey="mould_number" />
          </Column>
        </>
      ),
      cubes: (
        <>
          <Column width={150}>
            <HeaderCell>Specimen ID</HeaderCell>
            <Cell dataKey="specimen_id" />
          </Column>
          <Column width={150}>
            <HeaderCell>Mould Number</HeaderCell>
            <Cell dataKey="mould_number" />
          </Column>
        </>
      ),
    };

    return (
      <>
        {commonColumns}
        {typeColumns[searchType]}
      </>
    );
  };

  return (
    <Container>
      <Content>
        <Panel>
          <Stack spacing={10} alignItems="left">
            <InputGroup inside style={{ width: 300 }}>
              <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={setSearchTerm}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    fetchSearchResults();
                  }
                }}
              />
              <InputGroup.Button onClick={fetchSearchResults}>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>

            <ButtonGroup>
              {["Workorders", "Samples", "Cylinders", "Cubes"].map((type) => (
                <Button key={type} appearance={searchType === type.toLowerCase() ? "primary" : "default"} onClick={() => setSearchType(type.toLowerCase())}>
                  {type}
                </Button>
              ))}
            </ButtonGroup>
            {projects && <Text>Found {projects.length} results</Text>}
          </Stack>
        </Panel>

        <Panel>
          <Table
            loading={is_loading}
            height={400}
            hover={true}
            autoHeight={true}
            data={projects || []}
            bordered={true}
            cellBordered={true}
            headerHeight={30}
            rowHeight={30}
            style={{ fontSize: "0.8rem" }}
            onRowClick={(rowData) => {
              navigate(`/${searchType}/${rowData.id}`);
            }}
          >
            {renderColumns()}
          </Table>
        </Panel>
      </Content>
    </Container>
  );
}

export default Search;
